import { defineStore, acceptHMRUpdate } from 'pinia'
import { reactive, ref } from 'vue'

interface AppElements {
  heroPage: HTMLDivElement | null
  character: {
    eyeRight: SVGAElement | null
    armLeft: SVGAElement | null
    mouth: SVGAElement | null
    cloud: SVGAElement | null
  }
  contactPage: HTMLElement | null
  aboutPage: HTMLElement | null
  aboutHeading: HTMLHeadingElement | null
  aboutPortrait: HTMLImageElement | null
  qualityItem: HTMLDivElement[] | null
  aboutBkBlur: HTMLDivElement | null
  aboutBtnContainer: HTMLDivElement | null
}

interface AppState {
  showNavMenu: boolean
  showCookieMsg: boolean
  lightMode: boolean
  collapsedAppMenu: boolean
  messages: {
    showConfirmedSignup: boolean
  }
  nodes: AppElements
}

export const useAppStore = defineStore(
  'appStore',
  () => {
    const state = reactive<AppState>({
      lightMode: false,
      showNavMenu: false,
      showCookieMsg: true,
      collapsedAppMenu: false,
      messages: {
        showConfirmedSignup: false,
      },
      nodes: {
        heroPage: null,
        contactPage: null,
        aboutPage: null,
        aboutHeading: null,
        aboutPortrait: null,
        qualityItem: null,
        aboutBkBlur: null,
        aboutBtnContainer: null,
        character: {
          eyeRight: null,
          armLeft: null,
          mouth: null,
          cloud: null,
        },
      },
    })

    const lightMode = ref(false)

    const auth = ref<{ token: string | null, expiresIn: string | null }>({
      token: null,
      expiresIn: null,
    })

    function toggleMobileNav(isOpen?: boolean) {
      if (typeof isOpen === 'boolean') {
        state.showNavMenu = isOpen
      }
      else {
        state.showNavMenu = !state.showNavMenu
      }
    }

    type NestedKeys = keyof AppState | `${keyof AppState}.${string}`
    type NestedValues =
      | AppState[keyof AppState]
      | AppState[keyof AppState][keyof AppState[keyof AppState]]

    function updateState(key: NestedKeys, value: NestedValues) {
      if (!key) return
      const nestedKeys = (key as string).split('.')
      if (nestedKeys.length === 2) {
        state[nestedKeys[0]][nestedKeys[1]] = value
      }
      else {
        state[key as keyof AppState] = value
      }
    }

    function setAuth(token: string, expiresIn: string) {
      auth.value.token = token
      auth.value.expiresIn = expiresIn
    }

    return {
      state,
      lightMode,
      auth,
      toggleMobileNav,
      updateState,
      setAuth,
    }
  },
  {
    persist: {
      storage: persistedState.localStorage,
      paths: ['state.showCookieMsg', 'auth', 'messages'],
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
